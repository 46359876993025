<template>
	<el-form
		ref="simplePayGenerateLinkForm"
		label-position="top"
		:model="linkParams"
		size="small"
	>
		<div class="input-group" data-form-settings>
			<h2 class="mb-30">
				1) Customize your SimplePay link with pre-filled values:
			</h2>
			<el-row :gutter="15">
				<el-col :span="12" :xs="24">
					<el-form-item label="First Name" prop="firstName">
						<el-input
							v-model.trim="linkParams.firstName"
							type="text"
							data-cy="generate-link-first-name"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" :xs="24">
					<el-form-item label="Last Name" prop="lastName">
						<el-input
							v-model.trim="linkParams.lastName"
							type="text"
							data-cy="generate-link-last-name"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" :xs="24">
					<el-form-item label="Company" prop="company">
						<el-input
							v-model="linkParams.company"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :span="8" :xs="24">
					<el-form-item label="Country" prop="country">
						<el-select
							v-model="linkParams.country"
							:popper-append-to-body="false"
							@change="updateStateList"
						>
							<el-option
								v-for="country in countryList"
								:key="country.code"
								:label="country.name"
								:value="country.code"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8" :xs="24">
					<el-form-item label="Address" prop="address">
						<el-input
							v-model="linkParams.address"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8" :xs="24">
					<el-form-item label="Address 2" prop="address2">
						<el-input
							v-model="linkParams.address2"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :span="8" :xs="24">
					<el-form-item label="City" prop="city">
						<el-input
							v-model="linkParams.city"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8" :xs="24">
					<el-form-item label="State" prop="state">
						<el-select
							v-model="linkParams.state"
							filterable
							:popper-append-to-body="false"
						>
							<el-option
								v-for="state in stateList"
								:key="state.code"
								:label="state.name"
								:value="state.code"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8" :xs="24">
					<el-form-item label="Postal Code" prop="postalCode">
						<el-input
							v-model="linkParams.postalCode"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :span="12" :xs="24">
					<el-form-item label="Email" prop="email">
						<el-input
							v-model="linkParams.email"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" :xs="24">
					<el-form-item label="Phone" prop="phone">
						<el-input
							v-model="linkParams.phone"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :span="8" :xs="24">
					<el-form-item label="Order ID" prop="orderId">
						<el-input
							v-model="linkParams.orderId"
							type="text"
						></el-input>
					</el-form-item>
					<el-switch
						v-model="linkParams.hideOrder"
						inactive-text="Hide Order ID"
						active-color="#2689ba"
					></el-switch>
						
				</el-col>
				<el-col :span="8" :xs="24">
					<el-form-item label="PO Number" prop="poNumber">
						<el-input
							v-model="linkParams.poNumber"
							type="text"
						></el-input>
					</el-form-item>
						<el-switch class="el-form-item--small el-form-item"
								v-model="linkParams.hidePO"
								inactive-text="Hide PO Number"
								active-color="#2689ba"
						></el-switch>
				</el-col>
				<el-col :sm="8" :xs="24">
					<el-form-item label="Amount" prop="amount">
						<el-input
							v-model="linkParams.amount"
							type="number"
							step="0.01"
							@keypress="
								preventExcessDigitsOnAmount(
									linkParams.amount,
									$event
								)
							"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :span="24">
					<el-form-item label="Note" prop="note">
						<el-input
							v-model="linkParams.note"
							type="textarea"
							autosize
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</div>
		<div class="copy-links">
			<h2 class="mb-15">
				2) Generated Link
				<span v-if="isSandboxPage"> (Sandbox)</span>
				<span v-else> (Production)</span>:
			</h2>
			<p>
				<span v-if="isSandboxPage">{{ sandboxBaseUrl }}</span
				><span v-else>{{ prodBaseUrl }}</span
				>{{ urlParamValues }}
			</p>
			<h2 class="mt-30 mb-15">
				3) Pay Now Button Code
				<span v-if="isSandboxPage"> (Sandbox)</span>
				<span v-else> (Production)</span>:
			</h2>
			<el-checkbox v-model="opensInNewTab" class="mb-20"
				>Open SimplePay page in a new tab</el-checkbox
			>
			<p v-if="isSandboxPage">{{ sandboxBtnMarkup }}</p>
			<p v-else>{{ prodBtnMarkup }}</p>
		</div>
	</el-form>
</template>

<script lang="ts">
import { ArrayContents } from '../types/state'
import { defineComponent, ref, computed, reactive, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '../store'
import preventExcessDigitsOnAmount from '../shared-methods/preventExcessDigitsOnAmount'

// no-eslint

export default defineComponent({
	name: 'AdminGenerateLink',
	props: {
		isSandboxPage: Boolean
	},
	setup(props) {
		const store = useStore()
		const route = useRoute()
		const url = new URL(window.location.href) as URL
		let simplePayId = ref('')

		onMounted(async () => {
			store.dispatch({ type: 'hideLoadingScreen' })
			simplePayId.value = url.href.split('/')[5].toString()
			getLocations()
		})

		const opensInNewTab = ref(true)
		const prodBaseUrl = ref('https://simplepay.basysiqpro.com/?')
		const sandboxBaseUrl = ref(
			'https://simplepay-client-dev.devbasyspaasext.p.azurewebsites.net/?'
		)
		const payNowBtnStyle =
			'display:inline-block;line-height:1;border:1px solid #2689ba;border-radius:20px;box-shadow:1px 1px 2px 0 #98aeb3;color:#204d71;background:linear-gradient(135deg, #b7ddf3 0%, #d1efe8 100%);margin:0;padding-top:10px;padding-right:20px;padding-bottom:8px;padding-left:20px;font-size:11px;font-weight:500;text-decoration:none;letter-spacing:0.7px;'

		const linkParams = reactive({
			firstName: '',
			lastName: '',
			company: '',
			country: '',
			state: '',
			address: '',
			address2: '',
			city: '',
			postalCode: '',
			email: '',
			phone: '',
			orderId: '',
			poNumber: '',
			note: '',
			amount: '',
			hideOrder: false,
			hidePO: false
		} as any)

		const stateList = computed(
			(): Array<ArrayContents> => store.state.locales.stateList
		)
		const countryList = computed((): any => {
			return store.state.locales.countryList
		})
		const urlParamValues = computed((): string => {
			let params = new URLSearchParams()

			params.set('id', route.params.id as string)
			for (const key in linkParams) {
				if (linkParams[key]?.length ) {	
					params.set(key, linkParams[key])
				} 
				else if ((key === "hideOrder" || key === "hidePO") && linkParams[key]) {
					params.set(key, linkParams[key])
				}
				else {
					params.delete(key)
				}
			}

			return params.toString()
		})
		const linkTarget = computed((): string => {
			return opensInNewTab.value ? '_blank' : '_self'
		})
		const prodBtnMarkup = computed((): string => {
			return `<a href="${prodBaseUrl.value}${urlParamValues.value}" target="${linkTarget.value}" style="${payNowBtnStyle}">PAY NOW</a>`
		})
		const sandboxBtnMarkup = computed((): string => {
			return `<a href="${sandboxBaseUrl.value}${urlParamValues.value}" target="${linkTarget.value}" style="${payNowBtnStyle}">PAY NOW</a>`
		})

		async function updateStateList() {
			linkParams.state = ''
			linkParams.postalCode = ''
			const payload = {
				country: linkParams.country,
				simplePayId: simplePayId.value
			}
			try {
				await store.dispatch('getStates', payload)
			} catch (ex) {
				linkParams.state = ''
				console.log('location c init fail cf', ex)
			}
		}

		async function getLocations() {
			try {
				await store.dispatch('getCountries', simplePayId.value)
			} catch (ex) {
				console.log('location c init fail cf', ex)
			}
			// set initial state list to US
			const payload = {
				country: 'US',
				simplePayId: simplePayId.value
			}
			try {
				await store.dispatch('getStates', payload)
			} catch (ex) {
				console.log('location c init fail cf', ex)
			}
		}

		return {
			linkParams,
			opensInNewTab,
			prodBaseUrl,
			sandboxBaseUrl,
			urlParamValues,
			prodBtnMarkup,
			sandboxBtnMarkup,
			updateStateList,
			stateList,
			countryList,
			preventExcessDigitsOnAmount
		}
	}
})
</script>

<style lang="scss" scoped>
.copy-links p {
	padding: 12px 15px;
	border-radius: 3px;
	border: 1px solid #cacaca;
	background-color: #f3f3f3;
	color: #464646;
	font-size: 11px;
}
</style>
