<template>
	<admin-layout>
		<el-main class="form-wrapper">
			<el-alert
				v-show="showSuccess"
				title="Success! Your changes have been saved."
				type="success"
				@close="clearSuccess"
			></el-alert>
			<el-alert
				v-show="showError"
				title="There was an error, your changes were not saved."
				type="error"
				@close="clearError"
			></el-alert>
			<el-row :gutter="15">
				<el-col :sm="18" :xs="16" align="left">
					<h1 class="mb-30">
						<span v-show="merchantDisplayName !== null">{{
							merchantDisplayName
						}}</span>
						Settings
					</h1>
				</el-col>
				<el-col :sm="6" :xs="8" align="right">
					<el-button
						ref="backButton"
						class="back-button"
						icon="el-icon-back"
						size="medium"
						type="text"
						@click="goBack"
						>Back to list</el-button
					>
				</el-col>
			</el-row>
			<el-tabs type="border-card">
				<el-tab-pane label="Update Settings">
					<el-form
						ref="simplePayEditForm"
						label-position="top"
						:model="simplePayData.simplePaySettings"
						:rules="settingsFormValidationRules"
						size="small"
					>
						<el-collapse v-model="activePanels">
							<el-collapse-item
								title="SimplePay Settings"
								name="1"
								data-form-settings
								data-cy="admin-simplepay-settings-header-text"
							>
								<h4>Gateway IDs</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Merchant ID"
											prop="merchantId"
										>
											<el-input
												id="merchantId"
												v-model.trim="
													simplePayData.merchantId
												"
												type="text"
												data-cy="admin-merchantId"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row v-if="!isSandboxPage" :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Basys MID"
											prop="basysMID"
										>
											<el-input
												id="basysMID"
												v-model.trim="
													simplePayData.basysMID
												"
												type="text"
												data-cy="admin-basys-mid"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Secret API Key"
											prop="secretApiKey"
										>
											<el-input
												id="secretApiKey"
												v-model.trim="
													simplePayData.secretApiKey
												"
												type="text"
												data-cy="admin-secret-api-key"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Header Content</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Merchant Name"
											prop="merchantName"
										>
											<el-input
												id="merchantName"
												v-model="
													simplePayData
														.simplePaySettings
														.merchantName
												"
												type="text"
												data-cy="admin-merchant-name"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item label="Logo" prop="logo">
											<!-- <el-input
												id="logo"
												v-model="
													simplePayData
														.simplePaySettings.logo
												"
												type="textarea"
												:autosize="{
													minRows: 4,
													maxRows: 20
												}"
											></el-input>-->
											<el-upload
												class="logo-uploader"
												action="#"
												accept="image/jpeg, image/png"
												:auto-upload="false"
												:on-change="logoUpload"
												:show-file-list="false"
											>
												<img
													v-if="
														simplePayData
															.simplePaySettings
															.logo
													"
													:src="
														simplePayData
															.simplePaySettings
															.logo
													"
													class="logo-preview"
												/>
												<i
													v-else
													class="
														el-icon-plus
														logo-uploader-icon
													"
													data-cy="admin-logo-upload"
												></i>
												<template #tip>
													<br />
													<small
														class="
															el-upload__tip
															blue-text
														"
														>&#42; Logo should be
														144px tall, JPG or PNG,
														max size 1MB</small
													>
												</template>
											</el-upload>
											<el-button
												v-if="
													simplePayData
														.simplePaySettings.logo
												"
												round
												plain
												size="mini"
												type="danger"
												icon="el-icon-delete"
												@click="clearLogo"
												>Discard Logo</el-button
											>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="enlargeLogo">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.enlargeLogo
												"
												inactive-text="Enlarge Logo"
												active-color="#2689ba"
											></el-switch>
											<br />
											<small class="blue-text">
												* Logo will be enlarged from a
												36px display height to 72px
											</small>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Header Text"
											prop="headerText"
										>
											<el-input
												id="headerText"
												v-model="
													simplePayData
														.simplePaySettings
														.headerText
												"
												type="text"
												data-cy="admin-header-text"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Included Forms</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-checkbox
											v-model="
												simplePayData.simplePaySettings
													.hasCardForm
											"
											border
											size="small"
											data-cy="admin-has-card-checkbox"
											>CARD</el-checkbox
										>
										<el-checkbox
											v-model="
												simplePayData.simplePaySettings
													.hasAchForm
											"
											data-cy="admin-has-ach-checkbox"
											border
											size="small"
											class="mb-15"
											>ACH</el-checkbox
										>
										<br />
										<p
											v-show="!submitEnabled"
											class="
												small-text
												error-text
												mt-10
												mb-20
											"
										>
											* Must select at least one form
										</p>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										v-show="
											simplePayData.simplePaySettings
												.hasCardForm
										"
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Card Processor ID"
											prop="cardProcessorId"
										>
											<el-input
												id="cardProcessorId"
												v-model.trim="
													simplePayData
														.simplePaySettings
														.cardProcessorId
												"
												data-cy="admin-card-processorId"
												type="text"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										v-show="
											simplePayData.simplePaySettings
												.hasAchForm
										"
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="ACH Processor ID"
											prop="achProcessorId"
										>
											<el-input
												id="achProcessorId"
												v-model.trim="
													simplePayData
														.simplePaySettings
														.achProcessorId
												"
												type="text"
												data-cy="admin-ach-processorId"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										v-show="
											simplePayData.simplePaySettings
												.hasAchForm
										"
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<span class="el-form-item__label block"
											>ACH Type (SEC Code)</span
										>
										<el-radio-group
											v-model="
												simplePayData.simplePaySettings
													.achProcessing
											"
											data-cy="admin-web-or-ccd"
											size="small"
										>
											<el-radio label="web" border
												>WEB</el-radio
											>
											<el-radio label="ccd" border
												>CCD</el-radio
											>
										</el-radio-group>
										<small class="blue-text block">
											* If ACH Type is set to CCD,
											"Company Field" will be required on
											the ACH form.
										</small>
									</el-col>
								</el-row>
								<h4>Banner Message</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="hasBanner">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.hasBanner
												"
												inactive-text="Display Banner Message"
												active-color="#2689ba"
												data-cy="admin-banner-toggle"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 7, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Banner Type"
											prop="bannerType"
										>
											<el-select
												v-model="
													simplePayData
														.simplePaySettings
														.bannerType
												"
												data-cy="admin-banner-type-select"
											>
												<el-option
													v-for="item in bannerTypes"
													:key="item.value"
													:label="item.label"
													:value="item.value"
													data-cy="admin-banner-type-select-option"
												></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Banner Message"
											prop="bannerMessage"
										>
											<el-input
												id="bannerMessage"
												v-model="
													simplePayData
														.simplePaySettings
														.bannerMessage
												"
												type="text"
												data-cy="admin-banner-message"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4 class="mb-5">Redirects</h4>
								<small class="blue-text">
									Must use valid URLs beginning with
									'https://' or 'http://'
								</small>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
										class="mt-20"
									>
										<el-form-item
											label="Success URL"
											prop="successOverrideUrl"
											class="mb-5"
										>
											<el-input
												id="successOverrideUrl"
												v-model="
													simplePayData
														.simplePaySettings
														.successOverrideUrl
												"
												type="text"
												data-cy="admin-success-url"
											></el-input>
										</el-form-item>
										<small>
											* If provided, the customer will be
											taken to the Success URL instead of
											the confirmation screen after a
											successful transaction.
										</small>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
										class="mt-20"
									>
										<el-form-item
											label="Back URL"
											prop="backToHomeOverrideUrl"
											class="mb-5"
										>
											<el-input
												id="backToHomeOverrideUrl"
												v-model="
													simplePayData
														.simplePaySettings
														.backToHomeOverrideUrl
												"
												type="text"
												data-cy="admin-back-url"
											></el-input>
										</el-form-item>
										<small>
											* If provided, the confirmation
											screen will display a 'Back' button
											linking to this URL instead of the
											'Make Another Payment' button.
										</small>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
										class="mt-20"
									>
										<el-form-item
											label="Webhook URL"
											prop="webHookUrl"
											class="mb-5"
										>
											<el-input
												id="webHookUrl"
												v-model="
													simplePayData
														.simplePaySettings
														.webHookUrl
												"
												type="text"
												data-cy="admin-web-url"
											></el-input>
										</el-form-item>
										<small>
											* If provided, an anonymized copy of
											the transaction data will be sent to
											this URL. This may require the
											recipient to configure their server
											settings to allow POSTs from
											'https://simplepay.basysiqpro.com/'.
										</small>
									</el-col>
								</el-row>
							</el-collapse-item>
							<el-collapse-item
								v-show="
									simplePayData.simplePaySettings.hasCardForm
								"
								title="Card Form Settings"
								name="2"
								data-card-settings
								data-cy="admin-cardform-settings-header-text"
							>
								<h4>Accepted Cards</h4>
								<el-row :gutter="15" class="mb-30">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-checkbox-group
											v-model="
												simplePayData.simplePaySettings
													.acceptedCards
											"
										>
											<el-checkbox
												label="Visa"
												data-cy="admin-card-form-visa"
											></el-checkbox>
											<br />
											<el-checkbox
												label="MasterCard"
												data-cy="admin-card-form-mastercard"
											></el-checkbox>
											<br />
											<el-checkbox
												label="Discover"
												data-cy="admin-card-form-discover"
											></el-checkbox>
											<br />
											<el-checkbox
												label="American Express"
												data-cy="admin-card-form-american-express"
											></el-checkbox>
										</el-checkbox-group>
									</el-col>
								</el-row>
								<h4>Company Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="companyIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.companyIsIncluded
												"
												inactive-text="Company Field Included"
												active-color="#2689ba"
												data-cy="admin-card-form-companyIsIncluded"
												@change="
													changeCompanyIncludedCard
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="companyIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.companyIsRequired
												"
												inactive-text="Company Field Required"
												active-color="#2689ba"
												data-cy="admin-card-form-companyIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Company Field Label"
											prop="companyLabelTextCard"
										>
											<el-input
												id="companyLabelTextCard"
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.companyLabelText
												"
												type="text"
												data-cy="admin-card-form-companyLabelTextCard"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Address Fields</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="addressIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.addressIsIncluded
												"
												inactive-text="Address Fields Included"
												active-color="#2689ba"
												data-cy="admin-card-form-addressIsIncluded"
												@change="
													changeAddressIncludedCard
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="addressIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.addressIsRequired
												"
												inactive-text="Address Fields Required"
												active-color="#2689ba"
												data-cy="admin-card-form-addressIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Additional Information</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Additional Information Header"
											prop="additionalInfoHeaderTextCard"
										>
											<el-input
												id="additionalInfoHeaderTextCard"
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.additionalInfoHeaderText
												"
												type="text"
												data-cy="admin-card-form-additionalInfoHeaderText"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Email Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="emailIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.emailIsIncluded
												"
												inactive-text="Email Field Included"
												active-color="#2689ba"
												data-cy="admin-card-form-emailIsIncluded"
												@change="
													changeEmailIncludedCard
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="emailIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.emailIsRequired
												"
												inactive-text="Email Field Required"
												active-color="#2689ba"
												data-cy="admin-card-form-emailIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Phone Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="phoneIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.phoneIsIncluded
												"
												inactive-text="Phone Field Included"
												active-color="#2689ba"
												data-cy="admin-card-form-phoneIsIncluded"
												@change="
													changePhoneIncludedCard
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="phoneIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.phoneIsRequired
												"
												inactive-text="Phone Field Required"
												active-color="#2689ba"
												data-cy="admin-card-form-phoneIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Order ID Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="orderIdIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.orderIdIsIncluded
												"
												inactive-text="Order ID Field Included"
												active-color="#2689ba"
												data-cy="admin-card-form-orderIdIsIncluded"
												@change="
													changeOrderIdIncludedCard
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="orderIdIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.orderIdIsRequired
												"
												inactive-text="Order ID Field Required"
												active-color="#2689ba"
												data-cy="admin-card-form-orderIdIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Order ID Field Label"
											prop="orderIdLabelTextCard"
										>
											<el-input
												id="orderIdLabelTextCard"
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.orderIdLabelText
												"
												type="text"
												data-cy="admin-card-form-orderIdLabelTextCard"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>PO Number Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="poNumberIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.poNumberIsIncluded
												"
												inactive-text="PO Number Field Included"
												active-color="#2689ba"
												data-cy="admin-card-form-poNumberIsIncluded"
												@change="
													changePoNumberIncludedCard
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="poNumberIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.poNumberIsRequired
												"
												inactive-text="PO Number Field Required"
												active-color="#2689ba"
												data-cy="admin-card-form-poNumberIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="PO Number Field Label"
											prop="poNumberLabelTextCard"
										>
											<el-input
												id="poNumberLabelTextCard"
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.poNumberLabelText
												"
												type="text"
												data-cy="admin-card-form-poNumberLabelTextCard"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Note Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="noteIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.cardFields
														.noteIsIncluded
												"
												inactive-text="Note Field Included"
												active-color="#2689ba"
												data-cy="admin-card-form-noteIsIncluded"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
							</el-collapse-item>
							<el-collapse-item
								v-show="
									simplePayData.simplePaySettings.hasAchForm
								"
								title="ACH Form Settings"
								name="3"
								data-ach-settings
								data-cy="admin-ach-settings-header-text"
							>
								<h4>Company Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="companyIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.companyIsIncluded
												"
												:disabled="
													secIsCcd &&
													simplePayData
														.simplePaySettings
														.achFields
														.companyIsIncluded
												"
												inactive-text="Company Field Included"
												active-color="#2689ba"
												data-cy="admin-ach-form-companyIsIncluded"
												@change="
													changeCompanyIncludedACH
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="companyIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.companyIsRequired
												"
												:disabled="
													secIsCcd &&
													simplePayData
														.simplePaySettings
														.achFields
														.companyIsRequired
												"
												inactive-text="Company Field Required"
												active-color="#2689ba"
												data-cy="admin-ach-form-companyIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Company Field Label"
											prop="companyLabelTextACH"
										>
											<el-input
												id="companyLabelTextACH"
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.companyLabelText
												"
												type="text"
												data-cy="admin-ach-form-companyLabelTextACH"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Additional Information</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Additional Information Header"
											prop="additionalInfoHeaderTextACH"
										>
											<el-input
												id="additionalInfoHeaderTextACH"
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.additionalInfoHeaderText
												"
												type="text"
												data-cy="admin-ach-form-additionalInfoHeaderText"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Order ID Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="orderIdIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.orderIdIsIncluded
												"
												inactive-text="Order ID Field Included"
												active-color="#2689ba"
												data-cy="admin-ach-form-orderIdIsIncluded"
												@change="
													changeOrderIdIncludedACH
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="orderIdIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.orderIdIsRequired
												"
												inactive-text="Order ID Field Required"
												active-color="#2689ba"
												data-cy="admin-ach-form-orderIdIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Order ID Field Label"
											prop="orderIdLabelTextACH"
										>
											<el-input
												id="orderIdLabelTextACH"
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.orderIdLabelText
												"
												type="text"
												data-cy="admin-ach-form-orderIdLabelTextCard"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>PO Number Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="poNumberIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.poNumberIsIncluded
												"
												inactive-text="PO Number Field Included"
												active-color="#2689ba"
												data-cy="admin-ach-form-poNumberIsIncluded"
												@change="
													changePoNumberIncludedACH
												"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="poNumberIsRequired">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.poNumberIsRequired
												"
												inactive-text="PO Number Field Required"
												active-color="#2689ba"
												data-cy="admin-ach-form-poNumberIsRequired"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="PO Number Field Label"
											prop="poNumberLabelTextACH"
										>
											<el-input
												id="poNumberLabelTextACH"
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.poNumberLabelText
												"
												type="text"
												data-cy="admin-ach-form-poNumberLabelTextCard"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Driver's License Fields</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											prop="driversLicenseIsIncluded"
										>
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.driversLicenseIsIncluded
												"
												inactive-text="Driver's License Fields Included"
												active-color="#2689ba"
												data-cy="admin-ach-form-driversLicenseIsIncluded"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>DOB/SSN Fields</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="dobSSNIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.dobSSNIsIncluded
												"
												inactive-text="DOB/SSN Fields Included"
												active-color="#2689ba"
												data-cy="admin-ach-form-dobSSNIsIncluded"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<h4>Note Field</h4>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="noteIsIncluded">
											<el-switch
												v-model="
													simplePayData
														.simplePaySettings
														.achFields
														.noteIsIncluded
												"
												inactive-text="Note Field Included"
												active-color="#2689ba"
												data-cy="admin-ach-form-noteIsIncluded"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
							</el-collapse-item>
							<el-collapse-item
								title="Style Settings"
								name="4"
								data-style-settings
								data-cy="admin-style-settings-header-text"
							>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Page Header Color"
											prop="primaryColor"
											class="color-picker"
										>
											<el-color-picker
												v-model="
													simplePayData
														.simplePaySettings
														.primaryColor
												"
												:predefine="predefinedColors"
												size="medium"
												data-cy="admin-style-settings-primaryColor"
											></el-color-picker>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Form Text Color"
											prop="bodyFontColor"
											class="color-picker"
										>
											<el-color-picker
												v-model="
													simplePayData
														.simplePaySettings
														.bodyFontColor
												"
												:predefine="
													predefinedBodyFontColors
												"
												size="medium"
												data-cy=""
											></el-color-picker>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Form Header Text Color"
											prop="headerFontColor"
											class="color-picker"
										>
											<el-color-picker
												v-model="
													simplePayData
														.simplePaySettings
														.headerFontColor
												"
												:predefine="
													predefinedHeaderFontColors
												"
												size="medium"
												data-cy=""
											></el-color-picker>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Form Payment Button Background Color"
											prop="backgroundColor"
											class="color-picker"
										>
											<el-color-picker
												v-model="
													simplePayData
														.simplePaySettings
														.backgroundColor
												"
												:predefine="
													predefinedPaymentButtonColor
												"
												size="medium"
												data-cy=""
											></el-color-picker>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Form Background Color"
											prop="formBackgroundColor"
											class="color-picker"
										>
											<el-color-picker
												v-model="
													simplePayData
														.simplePaySettings
														.formBackgroundColor
												"
												:predefine="
													predefinedFormBackgroundColor
												"
												size="medium"
												data-cy=""
											></el-color-picker>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item prop="hasSecondaryColor">
											<el-switch
												v-model="hasSecondaryColor"
												inactive-text="Select a Secondary Color"
												active-color="#2689ba"
												data-cy="admin-style-settings-hasSecondaryColor"
												@change="resetSecondaryColor"
											></el-switch>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row v-show="hasSecondaryColor" :gutter="15">
									<el-col
										:sm="{ span: 15, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Page Background Color"
											prop="secondaryColor"
											class="color-picker"
										>
											<el-color-picker
												v-model="
													simplePayData
														.simplePaySettings
														.secondaryColor
												"
												:predefine="predefinedColors"
												size="medium"
												data-cy="admin-style-settings-secondaryColor"
											></el-color-picker>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 7, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Header Font"
											prop="headerFont"
										>
											<el-select
												v-model="
													simplePayData
														.simplePaySettings
														.headerFont
												"
												:style="{
													fontFamily:
														simplePayData
															.simplePaySettings
															.headerFont
												}"
												data-cy="admin-style-settings-header-select"
											>
												<el-option
													v-for="item in headerFont"
													:key="item.value"
													:label="item.label"
													:value="item.value"
													:style="{
														fontFamily: item.value
													}"
													data-cy="admin-style-settings-header-font"
												></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="15">
									<el-col
										:sm="{ span: 7, offset: 1 }"
										:xs="{ span: 24, offset: 0 }"
									>
										<el-form-item
											label="Body Font"
											prop="bodyFont"
										>
											<el-select
												v-model="
													simplePayData
														.simplePaySettings
														.bodyFont
												"
												:style="{
													fontFamily:
														simplePayData
															.simplePaySettings
															.bodyFont
												}"
											>
												<el-option
													v-for="item in bodyFont"
													:key="item.value"
													:label="item.label"
													:value="item.value"
													:style="{
														fontFamily: item.value
													}"
													data-cy="admin-style-settings-body-font"
												></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
							</el-collapse-item>
						</el-collapse>
						<el-row :gutter="15" class="mt-20">
							<!-- <el-col
								:sm="{ span: 4, offset: 16 }"
								:xs="12"
								align="left"
							>
								<el-button
									ref="backButton"
									class="back-button"
									type="info"
									@click="goBack"
									>Back</el-button
								>
							</el-col>-->
							<el-col
								:sm="{ span: 4, offset: 20 }"
								:xs="{ span: 12, offset: 12 }"
								align="right"
							>
								<el-button
									ref="submitButton"
									class="submit-button"
									type="primary"
									size="small"
									:disabled="!submitEnabled"
									data-cy="admin-save-button"
									@click="submitForm"
									>Save</el-button
								>
							</el-col>
						</el-row>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="Generate Link">
					<admin-generate-link
						:is-sandbox-page="isSandboxPage"
					></admin-generate-link>
				</el-tab-pane>
			</el-tabs>
		</el-main>
	</admin-layout>
</template>

<script>
import AdminLayout from '../global-components/layouts/AdminLayout.vue'
import AdminGenerateLink from '../global-components/AdminGenerateLink.vue'
import IqProApi from '../apis/IqProApi'
import { defineComponent } from 'vue'
import * as mp from '../apis/mixpanel'
import mixpanel from 'mixpanel-browser'

import _ from 'lodash'
export default defineComponent({
	name: 'AdminSimplePayEditView',
	components: {
		AdminLayout,
		AdminGenerateLink
	},
	inject: {
		d_id: 'distinct_id'
	},
	data() {
		// NO COMPOSITION API
		return {
			username: '',
			simplePayId: null,
			merchantDisplayName: 'SimplePay',
			fetchSettingsCopy: {},
			simplePayData: {
				secretApiKey: null,
				merchantId: null,
				basysMID: null,
				simplePaySettings: {
					merchantName: null,
					logo: null,
					cardProcessorId: null,
					achProcessorId: null,
					isActive: null,
					hasCardForm: null,
					hasAchForm: null,
					achProcessing: null,
					acceptedCards: [],
					primaryColor: null,
					secondaryColor: null,
					bodyFontColor: null,
					headerFontColor: null,
					backgroundColor: null,
					formBackgroundColor: null,
					enlargeLogo: null,
					bodyFont: null,
					headerFont: null,
					headerText: null,
					hasBanner: null,
					bannerType: null,
					bannerMessage: null,
					successMessage: null,
					declineMessage: null,
					successOverrideUrl: null,
					backToHomeOverrideUrl: null,
					webHookUrl: null,
					cardFields: {
						companyIsIncluded: null,
						companyIsRequired: null,
						companyLabelText: null,
						addressIsIncluded: null,
						addressIsRequired: null,
						additionalInfoHeaderText: null,
						emailIsIncluded: null,
						emailIsRequired: null,
						phoneIsIncluded: null,
						phoneIsRequired: null,
						orderIdIsIncluded: null,
						orderIdIsRequired: null,
						orderIdLabelText: null,
						poNumberIsIncluded: null,
						poNumberIsRequired: null,
						poNumberLabelText: null,
						noteIsIncluded: null
					},
					achFields: {
						companyIsIncluded: null,
						companyIsRequired: null,
						companyLabelText: null,
						noteIsIncluded: null,
						additionalInfoHeaderText: null,
						orderIdIsIncluded: null,
						orderIdIsRequired: null,
						orderIdLabelText: null,
						poNumberIsIncluded: null,
						poNumberIsRequired: null,
						poNumberLabelText: null,
						driversLicenseIsIncluded: null,
						dobSSNIsIncluded: null
					}
				}
			},
			activePanels: [],
			bannerTypes: [
				{
					value: 'warning',
					label: 'Warning (yellow)'
				},
				{
					value: 'info',
					label: 'Info (gray)'
				},
				{
					value: 'success',
					label: 'Success (green)'
				},
				{
					value: 'error',
					label: 'Error (red)'
				}
			],
			headerFont: [
				{
					value: 'Avenir',
					label: 'Avenir'
				},
				{
					value: 'Iowan Old Style',
					label: 'Iowan Old Style'
				},
				{
					value: 'Seravek',
					label: 'Seravek'
				},
				{
					value: 'Helvetica Neue',
					label: 'Helvetica Neue'
				},
				{
					value: 'Roboto Slab',
					label: 'Roboto Slab'
				},
				{
					value: 'Georgia',
					label: 'Georgia'
				},
				{
					value: 'Open Sans',
					label: 'Open Sans'
				}
			],
			bodyFont: [
				{
					value: 'Avenir',
					label: 'Avenir'
				},
				{
					value: 'Iowan Old Style',
					label: 'Iowan Old Style'
				},
				{
					value: 'Seravek',
					label: 'Seravek'
				},
				{
					value: 'Helvetica Neue',
					label: 'Helvetica Neue'
				},
				{
					value: 'Georgia',
					label: 'Georgia'
				},
				{
					value: 'Open Sans',
					label: 'Open Sans'
				}
			],
			predefinedColors: ['#0d304c'],
			predefinedBodyFontColors: ['#6d6c6b'],
			predefinedHeaderFontColors: ['#6d6c6b'],
			predefinedPaymentButtonColor: ['#21669e'],
			predefinedFormBackgroundColor: ['#fff'],
			hasSecondaryColor: false,
			showSuccess: false,
			showError: false,
			settingsFormValidationRules: {
				merchantName: [
					{
						required: false,
						message: 'Merchant Name is required',
						trigger: 'blur'
					}
				],
				merchantId: [
					{
						required: false,
						message: 'A valid Merchant ID is required',
						trigger: 'blur'
					}
				],
				secretApiKey: [
					{
						required: false,
						pattern: /^api_\w+$/,
						message: 'A valid Secret API Key is required',
						trigger: 'blur'
					}
				]
			}
		}
	},
	computed: {
		submitEnabled() {
			return (
				this.simplePayData.simplePaySettings.hasCardForm ||
				this.simplePayData.simplePaySettings.hasAchForm
			)
		},
		isSandboxPage() {
			return this.simplePayData.basysMID &&
				this.simplePayData.basysMID.length
				? false
				: true
		},
		secIsCcd() {
			return this.simplePayData.simplePaySettings.achProcessing == 'ccd'
		}
	},
	watch: {
		secIsCcd: 'companyRequiredFieldChange'
	},
	async mounted() {
		const pageURL = window.location.href
		this.simplePayId = pageURL.substr(pageURL.lastIndexOf('/') + 1)
		if (this.simplePayId !== null) {
			this.getSimplePaySettings()
		}
		this.username = this.$store.state.username
		mixpanel.identify(this.d_id)
	},
	methods: {
		async getSimplePaySettings() {
			this.$store.dispatch('showLoadingScreen')
			const resp = await IqProApi.getSimplePay(this.simplePayId)

			if (resp && resp.data) {
				let initSettings = Object.assign(this.simplePayData)
				let fetchedSettings = resp.data.text

				if (typeof fetchedSettings === 'string') {
					fetchedSettings = JSON.parse(fetchedSettings)
				}

				let initKeys = Object.keys(initSettings)
				let fetchedKeys = Object.keys(fetchedSettings)

				// mixpanel

				if (initKeys !== fetchedKeys) {
					this.simplePayData = Object.assign(
						{},
						initSettings,
						fetchedSettings
					)
				} else {
					this.simplePayData = fetchedSettings
				}

				this.fetchSettingsCopy = JSON.parse(
					JSON.stringify(fetchedSettings)
				)

				this.merchantDisplayName =
					this.simplePayData.simplePaySettings.merchantName
				this.hasSecondaryColor =
					this.simplePayData.simplePaySettings.primaryColor !==
					this.simplePayData.simplePaySettings.secondaryColor

				this.$store.dispatch('hideLoadingScreen')
			} else {
				console.log('No data')
				this.$router.push({ name: 'admin' })
			}
		},
		goBack() {
			this.$store.dispatch('showLoadingScreen')
			this.$router.push({ name: 'admin' })
		},
		async companyRequiredFieldChange() {
			if (this.secIsCcd) {
				this.simplePayData.simplePaySettings.achFields.companyIsIncluded = true
				this.simplePayData.simplePaySettings.achFields.companyIsRequired = true
			}
		},
		deepDiffObj(base, object) {
			if (!object)
				throw new Error(
					`The object compared should be an object: ${object}`
				)
			if (!base) return object
			const result = _.transform(object, (result, value, key) => {
				if (!_.has(base, key)) result[key] = value // fix edge case: not defined to explicitly defined as undefined
				if (!_.isEqual(value, base[key])) {
					result[key] =
						_.isPlainObject(value) && _.isPlainObject(base[key])
							? this.deepDiffObj(base[key], value)
							: value
				}
			})
			// map removed fields to undefined
			_.forOwn(base, (value, key) => {
				if (!_.has(object, key)) result[key] = undefined
			})
			return result
		},
		async submitForm() {
			this.$store.dispatch('showLoadingScreen')

			let isFormValid = false

			this.$refs.simplePayEditForm.validate((isValid) => {
				isFormValid = isValid
			})

			if (!isFormValid) {
				console.log('failed validation')
				this.$store.dispatch('hideLoadingScreen')
				return false
			}
			let apiRequest = Object.assign(this.simplePayData)

			let diff = {}

			diff = this.deepDiffObj(this.fetchSettingsCopy, this.simplePayData)

			if (!this.hasSecondaryColor) {
				apiRequest.secondaryColor = apiRequest.primaryColor
			}
			const resp = await IqProApi.updateSimplePay(
				this.simplePayId,
				apiRequest
			)
			if (resp && resp.data) {
				this.showSuccess = true
				this.getSimplePaySettings()
				this.$refs.simplePayEditForm.resetFields()

				mp.adminEditTrack(this.simplePayId)
				mp.adminEditSavedCountPeopleProp()
				mp.spEditPropChangedPeopleProp(diff)
			} else {
				this.showError = true
				this.$store.dispatch('hideLoadingScreen')
			}

			this.activePanels = []
		},
		clearSuccess() {
			this.showSuccess = false
		},
		clearError() {
			this.showError = false
		},
		changeCompanyIncludedCard() {
			if (
				!this.simplePayData.simplePaySettings.cardFields
					.companyIsIncluded
			) {
				this.simplePayData.simplePaySettings.cardFields.companyIsRequired = false
			}
		},
		changeAddressIncludedCard() {
			if (
				!this.simplePayData.simplePaySettings.cardFields
					.addressIsIncluded
			) {
				this.simplePayData.simplePaySettings.cardFields.addressIsRequired = false
			}
		},
		changeEmailIncludedCard() {
			if (
				!this.simplePayData.simplePaySettings.cardFields.emailIsIncluded
			) {
				this.simplePayData.simplePaySettings.cardFields.emailIsRequired = false
			}
		},
		changePhoneIncludedCard() {
			if (
				!this.simplePayData.simplePaySettings.cardFields.phoneIsIncluded
			) {
				this.simplePayData.simplePaySettings.cardFields.phoneIsRequired = false
			}
		},
		changeOrderIdIncludedCard() {
			if (
				!this.simplePayData.simplePaySettings.cardFields
					.orderIdIsIncluded
			) {
				this.simplePayData.simplePaySettings.cardFields.orderIdIsRequired = false
			}
		},
		changePoNumberIncludedCard() {
			if (
				!this.simplePayData.simplePaySettings.cardFields
					.poNumberIsIncluded
			) {
				this.simplePayData.simplePaySettings.cardFields.poNumberIsRequired = false
			}
		},
		changeCompanyIncludedACH() {
			if (
				!this.simplePayData.simplePaySettings.achFields
					.companyIsIncluded
			) {
				this.simplePayData.simplePaySettings.achFields.companyIsRequired = false
			}
		},
		changeOrderIdIncludedACH() {
			if (
				!this.simplePayData.simplePaySettings.achFields
					.orderIdIsIncluded
			) {
				this.simplePayData.simplePaySettings.achFields.orderIdIsRequired = false
			}
		},
		changePoNumberIncludedACH() {
			if (
				!this.simplePayData.simplePaySettings.achFields
					.poNumberIsIncluded
			) {
				this.simplePayData.simplePaySettings.achFields.poNumberIsRequired = false
			}
		},
		resetSecondaryColor() {
			this.simplePayData.simplePaySettings.secondaryColor =
				this.simplePayData.simplePaySettings.primaryColor
		},
		logoUpload(logo) {
			const file = logo.raw
			const isLt1M = file.size / 1024 / 1024 < 1
			if (!isLt1M) {
				this.$message.error('File size may not exceed 1MB')
				return false
			}
			const reader = new FileReader()
			const that = this
			reader.onload = function () {
				that.simplePayData.simplePaySettings.logo = reader.result
			}
			// convert image file to base64 string
			reader.readAsDataURL(file)
		},
		clearLogo() {
			this.simplePayData.simplePaySettings.logo = ''
		}
	}
})
</script>

<style lang="scss" scoped>
.form-wrapper {
	:deep(#logo) {
		font-size: 10px;
		line-height: 1em;
		min-height: 32px !important;
	}
	:deep(.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active),
	:deep(.el-tabs--border-card
			> .el-tabs__header
			.el-tabs__item:not(.is-disabled):hover) {
		color: #2e88ba;
	}
	:deep(.el-collapse-item__header) {
		font-size: 15px;
		.el-collapse-item__arrow {
			padding: 5px;
			border-radius: 20px;
			background-color: #fff;
			border: 1px solid $blue;
			width: 28px;
			height: 28px;
			box-sizing: border-box;
			&:before {
				font-size: 16px;
				width: 16px;
				height: 16px;
				color: $blue;
			}
		}
	}
	:deep(.el-switch__label--left) {
		color: #6d6c6b;
		span {
			font-size: 13px !important;
			font-weight: 200;
		}
	}
	:deep(.back-button) {
		text-decoration: underline;
		box-shadow: none;
		color: $blue;
		position: relative;
		top: 4px;
	}
	:deep(.submit-button) {
		padding: 0;
		width: 100%;
		font-size: 13px;
		line-height: 13px;
	}
	:deep(.el-checkbox:not(.is-bordered) .el-checkbox__input) {
		top: 5px !important;
	}
	:deep(.color-picker .el-form-item__content) {
		display: inline-block;
		top: 12px;
		left: 10px;
	}
}
.el-popper {
	:deep(.el-select-dropdown__item) {
		font-size: 12px !important;
		&.selected {
			color: $blue !important;
		}
	}
}
.logo-uploader-icon {
	display: block;
	font-size: 28px;
	color: #8c939d;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
}
.logo-preview {
	width: auto;
	height: 36px;
	padding: 5px;
	display: block;
}
.el-upload__tip {
	margin-top: 0;
}
.logo-uploader + .el-button {
	margin: 10px 0;
}
</style>

<style lang="scss">
.logo-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.logo-uploader .el-upload:hover {
	border-color: $blue;
}
</style>
