
import { ArrayContents } from '../types/state'
import { defineComponent, ref, computed, reactive, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '../store'
import preventExcessDigitsOnAmount from '../shared-methods/preventExcessDigitsOnAmount'

// no-eslint

export default defineComponent({
	name: 'AdminGenerateLink',
	props: {
		isSandboxPage: Boolean
	},
	setup(props) {
		const store = useStore()
		const route = useRoute()
		const url = new URL(window.location.href) as URL
		let simplePayId = ref('')

		onMounted(async () => {
			store.dispatch({ type: 'hideLoadingScreen' })
			simplePayId.value = url.href.split('/')[5].toString()
			getLocations()
		})

		const opensInNewTab = ref(true)
		const prodBaseUrl = ref('https://simplepay.basysiqpro.com/?')
		const sandboxBaseUrl = ref(
			'https://simplepay-client-dev.devbasyspaasext.p.azurewebsites.net/?'
		)
		const payNowBtnStyle =
			'display:inline-block;line-height:1;border:1px solid #2689ba;border-radius:20px;box-shadow:1px 1px 2px 0 #98aeb3;color:#204d71;background:linear-gradient(135deg, #b7ddf3 0%, #d1efe8 100%);margin:0;padding-top:10px;padding-right:20px;padding-bottom:8px;padding-left:20px;font-size:11px;font-weight:500;text-decoration:none;letter-spacing:0.7px;'

		const linkParams = reactive({
			firstName: '',
			lastName: '',
			company: '',
			country: '',
			state: '',
			address: '',
			address2: '',
			city: '',
			postalCode: '',
			email: '',
			phone: '',
			orderId: '',
			poNumber: '',
			note: '',
			amount: '',
			hideOrder: false,
			hidePO: false
		} as any)

		const stateList = computed(
			(): Array<ArrayContents> => store.state.locales.stateList
		)
		const countryList = computed((): any => {
			return store.state.locales.countryList
		})
		const urlParamValues = computed((): string => {
			let params = new URLSearchParams()

			params.set('id', route.params.id as string)
			for (const key in linkParams) {
				if (linkParams[key]?.length ) {	
					params.set(key, linkParams[key])
				} 
				else if ((key === "hideOrder" || key === "hidePO") && linkParams[key]) {
					params.set(key, linkParams[key])
				}
				else {
					params.delete(key)
				}
			}

			return params.toString()
		})
		const linkTarget = computed((): string => {
			return opensInNewTab.value ? '_blank' : '_self'
		})
		const prodBtnMarkup = computed((): string => {
			return `<a href="${prodBaseUrl.value}${urlParamValues.value}" target="${linkTarget.value}" style="${payNowBtnStyle}">PAY NOW</a>`
		})
		const sandboxBtnMarkup = computed((): string => {
			return `<a href="${sandboxBaseUrl.value}${urlParamValues.value}" target="${linkTarget.value}" style="${payNowBtnStyle}">PAY NOW</a>`
		})

		async function updateStateList() {
			linkParams.state = ''
			linkParams.postalCode = ''
			const payload = {
				country: linkParams.country,
				simplePayId: simplePayId.value
			}
			try {
				await store.dispatch('getStates', payload)
			} catch (ex) {
				linkParams.state = ''
				console.log('location c init fail cf', ex)
			}
		}

		async function getLocations() {
			try {
				await store.dispatch('getCountries', simplePayId.value)
			} catch (ex) {
				console.log('location c init fail cf', ex)
			}
			// set initial state list to US
			const payload = {
				country: 'US',
				simplePayId: simplePayId.value
			}
			try {
				await store.dispatch('getStates', payload)
			} catch (ex) {
				console.log('location c init fail cf', ex)
			}
		}

		return {
			linkParams,
			opensInNewTab,
			prodBaseUrl,
			sandboxBaseUrl,
			urlParamValues,
			prodBtnMarkup,
			sandboxBtnMarkup,
			updateStateList,
			stateList,
			countryList,
			preventExcessDigitsOnAmount
		}
	}
})
